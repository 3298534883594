import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import img from "./simple-installer.gif";
import { graphql } from "gatsby";
export const query = graphql`
query ($myComponentPath: String!) {
    mdx(fileAbsolutePath: {eq: $myComponentPath}) {
        frontmatter {
            image {
                publicURL
            }
            imageSmall {
                publicURL
            }
        }
    }
}
`;
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}>
      <MDXTag name="p" components={components}>{`In this article you're going to learn about a program installation manager similar to `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://chocolatey.org/"
        }}>{`chocolatey`}</MDXTag>{` or `}<MDXTag name="inlineCode" components={components} parentName="p">{`apt-get`}</MDXTag>{`, which is running on node.js. It is called `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://github.com/webuniverseio/SimpleInstaller"
        }}><MDXTag name="inlineCode" components={components} parentName="a">{`SimpleInstaller`}</MDXTag></MDXTag>{` and it can also work as a downloader and a task runner. We're going to look at a few simple usage examples and then we're going to see how SimpleInstaller can be integrated into project setup workflow, to bring new members of the team up to speed.`}</MDXTag>
      <MDXTag name="h2" components={components}>{`Top level highlights`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">{`is crossplatform (tested on windows and debian)`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`downloads files (optional) and runs commands`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`skips already installed programs`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`could be used as a simple task runner`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`could work as a downloader`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`simple and because of that flexible and extendable `}<MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://github.com/webuniverseio/SimpleInstaller#api"
          }}>{`api`}</MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`tested with 100% coverage`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`uses harmony mode for generators and `}<MDXTag name="inlineCode" components={components} parentName="li">{`npm co`}</MDXTag>{` to manage execution flow`}</MDXTag>
      </MDXTag>
      <MDXTag name="h2" components={components}>{`Example: Installing git on Debian`}</MDXTag>
      <MDXTag name="p" components={components}>{`Lets take a look at how it can be used to install a program. Here it installs git via `}<MDXTag name="inlineCode" components={components} parentName="p">{`apt-get`}</MDXTag>{` on debian:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-js"
        }}>{`var co = require('co');
var SimpleInstaller = require('simple-installer');

co(function* () {
    yield new SimpleInstaller({
        prefix: 'apt-get install ',
        name: 'git'
    }).run();
});
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Since SimpleInstaller is using generators, it is `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "/asynchronous-programming-with-ES6-generators-promises-and-npm-co/"
        }}>{`recommended`}</MDXTag>{` to use npm modules like `}<MDXTag name="inlineCode" components={components} parentName="p">{`co`}</MDXTag>{` to keep asynchronous code in a synchronous / maintainable manner.`}</MDXTag>
      <MDXTag name="h2" components={components}>{`Example: Installing ruby on Windows`}</MDXTag>
      <MDXTag name="p" components={components}>{`This example downloads ruby and installs it on Windows:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-js"
        }}>{`var co = require('co');
var SimpleInstaller = require('simple-installer');

co(function* () {
    var installer = new SimpleInstaller({
        link: 'https://dl.bintray.com/oneclick/rubyinstaller/rubyinstaller-2.2.1-x64.exe?direct',
        name: 'ruby',
        postfix: ' /verysilent /dir="c:\\Ruby2" /tasks="assocfiles,modpath"'
    });
    yield installer.run();
});
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Fortunately rubyinstaller has command line interface which supports `}<MDXTag name="inlineCode" components={components} parentName="p">{`/verysilent`}</MDXTag>{` mode - which allows us to install a program without a single popup.`}</MDXTag>
      <MDXTag name="h2" components={components}>{`Example: Batch installation`}</MDXTag>
      <div style={{
        "textAlign": "center",
        "marginTop": "1em"
      }}>
  <img src={img} alt="Installation process" />
      </div>
      <MDXTag name="p" components={components}>{`When we setup a new machine for a new team member, or when we start on a project developed by another company, we need to install all dependencies which are required for project to build and run. Those could be node modules, ruby gems, git repositories, bower components, etc... Lets take a look at how we could use SimpleInstaller to make project dependencies installation as simple as possible.`}</MDXTag>
      <MDXTag name="p" components={components}>{`First we're going to create a module `}<MDXTag name="inlineCode" components={components} parentName="p">{`config.js`}</MDXTag>{`, which exports an array of program `}<MDXTag name="inlineCode" components={components} parentName="p">{`info`}</MDXTag>{` objects, which are required to build and run our project:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-js"
        }}>{`'use strict';

var path = require('path');

module.exports = [{
    link: 'https://dl.bintray.com/oneclick/rubyinstaller/rubyinstaller-2.2.1-x64.exe?direct',
    name: 'ruby',
    postfix: ' /verysilent /dir="c:\\Ruby2" /tasks="assocfiles,modpath"'
},
{
    link: 'http://github.com/msysgit/msysgit/releases/download/' +
    'Git-1.9.4-preview20140929/Git-1.9.4-preview20140929.exe',
    name: 'git.exe',
    postfix: ' /DIR="c:\\Git" /VERYSILENT /NORESTART /NOCANCEL /SP- /CLOSEAPPLICATIONS /RESTARTAPPLICATIONS /NOICONS ' +
    '/COMPONENTS="icons,ext\\reg\\shellhere,assoc,assoc_sh" /LOADINF="' + path.resolve(__dirname, 'git.inf') + '"'
}, {
    prefix: 'npm i ',
    name: 'karma',
    postfix: '-cli -g'
}, {
    prefix: 'npm i ',
    name: 'grunt',
    postfix: '-cli -g'
}, {
    prefix: 'npm i ',
    name: 'bower',
    postfix: ' -g'
}, {
    prefix: 'SET PATH=%PATH%;c:\\Ruby2\\bin&&gem install ',
    name: 'compass',
    postfix: ' -v 1.0.1'
}, {
    prefix: 'echo ',
    name: 'bower_components',
    postfix: '&&SET PATH=%PATH%;c:\\Git\\bin\\&&bower install'
}];
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Here we can see that project requires a few global node packages, along with git, ruby and compass gem. As a final step it also runs `}<MDXTag name="inlineCode" components={components} parentName="p">{`bower install`}</MDXTag>{` to install client side dependencies.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Next we need to create a module `}<MDXTag name="inlineCode" components={components} parentName="p">{`installer.js`}</MDXTag>{` which will contain installation logic:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-js"
        }}>{`'use strict';

var co = require('co'); //use co and lodash to simplify code
var _ = require('lodash');
var SimpleInstaller = require('simple-installer');
var config = require('./config'); //our config

co(function* runCoroutine() {
    //first we convert array of objects to array of generators
    var setup = _.map(config, function* runInstaller(info) {
        var installer = new SimpleInstaller(info);
        yield installer.run();
    });

    //then iterate and run installers, one at a time
    for (var i = 0, length = setup.length; i < length; i++) {
        yield setup[i];
    }

    console.log('Installation finished'.yellow);
}).catch(function (ex) {
    setTimeout(function throwUnhandledException() {
        console.log('setup failed'.red);
        console.log((ex.message ? ex.message : ex).red);
        throw ex;
    }, 0);
});
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`And finally we need to find a good place to run installer.js. I like using it in package.json postinstall script:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-js"
        }}>{`{
  "scripts": {
    "preinstall": "npm cache clean",
    "postinstall": "node --harmony installer.js&&npm shrinkwrap --dev",
    "test": "echo \"Error: no test specified\" && exit 1"
  }
}
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`That way, after `}<MDXTag name="inlineCode" components={components} parentName="p">{`npm install`}</MDXTag>{`, once we get all node_modules, we can run our installer, which in turn will install all missing dependencies.`}</MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {
  "title": "Manage project dependencies with simple-installer",
  "date": "2015-04-05T10:52:22.000Z",
  "overview": "Download and/or install programs in similar manner to chocolatey or similar installation managers.",
  "image": "./image.png",
  "imageSmall": "./imageSmall.png",
  "type": "post"
};
    